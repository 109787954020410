import React from 'react'
import { graphql } from 'gatsby'
import { HTMLRenderer, Layout, PageWrapper, HcpMobileBanner } from '@retina-packages/retina-theme-bootstrap';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import { SupportingYourParentsWrapper } from './styles'
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { hcpSYP } from './constants';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, readCaseModalDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';
import { HcpPage } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle';
import { EsHeaderContainer } from '../../../css/common/style';
import { deriveCommunityResourceVideo } from '@retina-packages/retina-theme-bootstrap/packages/helpers';
import MultiColVideoContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MultiColVideoContainer';

/** Renders supporting-your-patients-page template
 *
 * @param props prop
 * @returns
 */
const SupportingYourPatientsTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsSYM = props.pageContext
  const htmlStaticFilesSYM = {
    nonSVGImages: pgContextsSYM.nonSVGImages,
    svgMediaImages: pgContextsSYM.svgMediaImages,
    allMediaDocument: pgContextsSYM.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpSYP.hcpHomeMainMenu, siteFooterMenu: hcpSYP.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = hcpSYP.hcpBanner
  const carouselTitle = hcpSYP.hcpHomeCarousel
  const footerTitle = hcpSYP.hcpFooter
  const exitPopupTitle = hcpSYP.hcpExitPopup
  const hcpSwitcherTitle = hcpSYP.hcpHCPSwitcher
  const topNavTitle = hcpSYP.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const hcpReferenceSYP = footRefHTMLBuilder({ blocks, title: hcpSYP.hcpFootRef });
  const siteLogos: any = deriveMultiLogo({ blocks, title: hcpSYP.hcpSiteLogo })
  const anchorelink = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpAnchorelinkPara });
  const htmlData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpRealWorldPara });
  const seekTrialData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpClinicalTrialPara });
  const bannerHtmlData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpHTMLBanner });
  const socialBlock = footRefHTMLBuilder({ blocks, title: hcpSYP.socialBlock });


  const modals: any = readCaseModalDataBuilder(blocks, [hcpSYP.hcpRCSModal1, hcpSYP.hcpRCSModal2, hcpSYP.hcpRCSModal3, hcpSYP.hcpRCSModal4, hcpSYP.hcpRCSModal5])
  const logoGardenHTML = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpLogoGarden })
  const allisonVideoHead = fullHTMLDataBuilder({ blocks, title: hcpSYP.video1Heading })
  const allisonVideo = blocks.filter((crco: any) => crco.relationships.field_reusable_paragraph.label === hcpSYP.video1).map(
    (crcoi: any) => deriveCommunityResourceVideo(crcoi))?.at(0)
  const vidHtmlData = fullHTMLDataBuilder({ blocks, title: hcpSYP.video2Section })
  const videoSection = blocks.filter((crco: any) => crco.relationships.field_reusable_paragraph.label === hcpSYP.video2).map(
    (crcoi: any) => deriveCommunityResourceVideo(crcoi))?.at(0)

  return (
    <div className='hcppage'>
    <HcpPage key="hcppage" className='hcp-footer-ref'>
    <HcpMobileBanner className="hcpmobilewrapper hcpsupportpatience hcp-supporting-patient-footer-wrapper">
      {props.pageContext !== null && props.pageContext.metaInfo !== null && (
        <MetaTagContainer metaData={props.pageContext.metaInfo} />
      )}
      <EsHeaderContainer>
        <Layout
          title={"siteTitle"}
          location={props.location}
          data={mainMenu}
          modals={modals}
          mdata={footerMenu}
          socialBlock={socialBlock}
          footerData={footerText}
          audience={audience}
          exitPopData={exitData}
          footref={hcpReferenceSYP}
          hcplinks={hcpSwitcher}
          topNavigation={topNavs}
          siteLogos={siteLogos}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          gtmBackToTopLabel={hcpSYP.backToTopGTM}
          backToTopContent={retinaConfig.backToTopContent}
          staticMediaFiles={htmlStaticFilesSYM}
          showRefContent={retinaConfig.showRefContent}
          hideRefContent={retinaConfig.hideRefContent}
          hcpValidate={retinaConfig.hcpValidate}
          preIndexUrl = {retinaConfig.preIndexUrl}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          footerClassName={hcpSYP.footerClassName}
          cpraFooter={true}
          cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
          cpraOT={true}
          cpraOTEnv={process.env.OT_ENV}
          cpraOTuuid={process.env.OT_UUID}
          {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
        >
          <PageWrapper className='pagewrapper supporting-your-patients hcp-supporting-your-patient'>
            {/* Retina Canada HCP - "Supporting Your Patients" page - "Re-testing after 1 year" pop-up - EN */}
            <SupportingYourParentsWrapper className='supportparentswrapper'>
              {/* Retina Canada HCP - "Supporting your patients" page - "Connecting Patients with" Banner component-EN */}
              {/* Banner section */}
              <MobileBanner className="mobile-banner clinical-trials-banner">
                <HTMLRenderer data={htmlStaticFilesSYM} html={bannerHtmlData} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
              </MobileBanner>
              <HTMLRenderer data={htmlStaticFilesSYM} html={anchorelink} key="anchorelink" />
              <HTMLRenderer data={htmlStaticFilesSYM} html={htmlData} key="htmlData" />
              <HTMLRenderer data={htmlStaticFilesSYM} html={logoGardenHTML} simpleImage={true} key="logoGardenHTML" />
              <HTMLRenderer data={htmlStaticFilesSYM} html={allisonVideoHead} key="allisonVideoHead" />
              <MultiColVideoContainer
                accountId={retinaConfig.accountId}
                communityResourcesVideoSection={allisonVideo}
                viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                { ...hcpSYP.video1Attrs }
              />
              <HTMLRenderer data={htmlStaticFilesSYM} html={vidHtmlData} key="vidHtmlData" />
              <MultiColVideoContainer
                accountId={retinaConfig.accountId}
                communityResourcesVideoSection={videoSection}
                viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                { ...hcpSYP.video23Attrs }
              />
              <HTMLRenderer data={htmlStaticFilesSYM} html={seekTrialData} key="seekTrialData" />
            </SupportingYourParentsWrapper>
          </PageWrapper>
        </Layout>
      </EsHeaderContainer>
    </HcpMobileBanner>
    </HcpPage>
    </div>
  )
}

export default SupportingYourPatientsTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...HcpSYPPageQuery
      }
    }
  }
`
