export const hcpSYP: any = {
    hcpHomeMainMenu: "Main Navigational Menu HCP Retina Brazil (PT)",
    hcpHomeFooterMenu: "Footer Navigation Menu HCP Retina Brazil (PT)",
    hcpHomeCarousel: "",
    hcpBanner: "Banner - Supporting Your Patient",
    hcpHTMLBanner: "Banner - HTML - SYP - HCP - Brazil (PT)",
    hcpFooter: "Footer - HCP - Retina Brazil (PT)",
    hcpExitPopup: "Exit Popup HCP Brazil (PT)",
    hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
    hcpTopNav: "Top Navigation Menu HCP Retina Brazil (PT)",
    hcpFooterCallout: "HCP Homepage Footer Navigation Callout HTML - Canada",
    hcpSiteLogo: "Site logos - Retina Brazil (PT)",
    socialBlock: "Footer Social Icons",
    hcpAmericalData: "American Academy of Ophthalmology HTML - Canada",
    hcpAdvancingScience: "Advancing Science HTML - Canada",
    hcpOverlap: "Overlapping presentations of IRD HTML - Canada",
    hcpFootRef: "HCP-Supporting Your Patient-References - Retina Brazil (PT)",
    hcpHomeVideoCarousel: "DNA Video and Carousel",
    hcpAnchorelinkPara: "Anchor Link - Resource Center - Retina Brazil (PT)",
    hcpRealWorldPara: "Real World Case Studies - Resource Center - Retina Brazil (PT)",
    hcpClinicalTrialPara: "HCP-Seeking a Clinical Trial - Resource Center - Retina Brazil (PT)",
    hcpVideoHeadingPara: "Video Heading Supporting your patient",
    hcpPatientKnowPara: "Logo garden section - Canada - HCP - Supporting your patients",
    hcpLogoGarden: "Resource Center - Education, Support, & Community HTML - Brazil (PT)",
    video1Heading: "Cesar Video heading - Community Resources - Retina Brazil (PT)",
    video1: "Resource Center Cesar - Retina Brazil (PT)",
    video2Section: "Video - Resource Center - Retina Brazil (PT)",
    video2: "Video Section - Resource Center - Retina Brazil (PT)",
    hcpRCSModal1: "Revealing Modal - Retina Brazil",
    hcpRCSModal2: "Overlapping Modal - Retina Brazil",
    hcpRCSModal3: "Denovo Modal - Retina Brazil",
    hcpRCSModal4: "Retesting Modal - Retina Brazil",
    hcpRCSModal5: "Benefit Modal - Retina Brazil",
    backToTopGTM: "back to top - supporting your patients - hcp",
    footerClassName: "supporting-your-patients-footer",
    video1Attrs: {
      viewTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "genetic testing story of cesar - view transcript",
          "className": "gtm-accordion",
          "aria-label": "Mostrar transcrição para A busca de César pelo seu diagnóstico genético"
        }
      ],
      hideTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "genetic testing story of cesar - hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Mostrar transcrição para A busca de César pelo seu diagnóstico genético"
        }
      ]
    },
    video23Attrs: {
      viewTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "genes 101 - view transcript",
          "className": "gtm-accordion",
          "aria-label": "Mostrar transcrição para Princípios de Genética"
        },
        {
          "data-gtm-event-label": "inheritance 101 - view transcript",
          "className": "gtm-accordion",
          "aria-label": "Mostrar transcrição para Hereditariedade Genética"
        }
      ],
      hideTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "genes 101 - hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Mostrar transcrição para Princípios de Genética"
        },
        {
          "data-gtm-event-label": "inheritance 101 - hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Mostrar transcrição para Hereditariedade Genética"
        }
      ]
    },

  }
